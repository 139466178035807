import { ProtocoloConfigService } from 'service';
import { ConfigTypes } from 'state/types/ConfigTypes';
import { Config } from 'types';

let running = false;

export const refreshConfig =
  (entidadeId: number, onFinish: (config?: Config) => void) => dispatch => {
    if (running) {
      onFinish();
      return;
    }

    running = true;
    let config: Config | undefined;

    return ProtocoloConfigService.getConfig(entidadeId)
      .then(({ data }) => {
        dispatch({
          type: ConfigTypes.REFRESH,
          payload: data
        });
        config = data;
      })
      .finally(() => {
        running = false;
        onFinish(config);
      });
  };
