import { ErrorPage, NotificationActions } from '@elotech/components';
import { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

export const SILENT_REJECTION = 'SILENT_REJECTION';

type Props = {
  children: ReactNode;
  showNotification: (action: any) => void;
};

type State = {
  hasError: boolean;
  error: any;
  info: any;
};

const DEFAULT_MESSAGE = 'Ops, ocorreu algum problema inesperado.';
const DEFAULT_VMESSAGE_VIOLATION = 'Validação.';

export const getErrorMessageDetails = async (event: any) => {
  const error = event.reason || event.error || event;

  // Não mostra mensagem se for cancelamento por parte do usuário
  if (error === SILENT_REJECTION || error?.__proto__?.__CANCEL__) {
    return;
  }

  const reason = error?.response || error || event;

  const violations = error?.response?.data
    ? (await getErrorData(error.response))?.violations
    : null;
  if (violations?.length) {
    return {
      level: 'error',
      title: await getErrorMessage(reason, true),
      message: violations.map(violation => violation.message).join(' / ')
    };
  }

  return {
    level: 'error',
    message: await getErrorMessage(reason, false)
  };
};

const getErrorData = async ({ data }) => {
  if (data?.constructor?.name === 'Blob' && data.type === 'application/json') {
    return data.text().then(text => JSON.parse(text));
  }
  return data;
};

const getErrorMessage = async (error: any, violationError: boolean) => {
  if (!error?.data) {
    return error?.message || DEFAULT_MESSAGE;
  }
  const data = await getErrorData(error);
  if (data && data.message) {
    return violationError ? DEFAULT_VMESSAGE_VIOLATION : data.message;
  }
  return typeof data === 'object' ? DEFAULT_MESSAGE : data;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
      info: null
    };
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.handleRejections);
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.handleRejections);
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error,
      info
    });
  }

  handleRejections = async event => {
    //eslint-disable-next-line no-console
    console.warn('Rejeição de promise não tratada', { event }, event?.error);
    event.preventDefault?.();
    event.stopPropagation?.();
    getErrorMessageDetails(event).then(
      details => details && this.props.showNotification(details)
    );
    return false;
  };

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage message={this.state.error?.toString() || DEFAULT_MESSAGE} />
      );
    }

    return this.props.children;
  }
}

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const ConnectedErrorBoundary = connect(null, mapDispatchToProps)(ErrorBoundary);

export { ConnectedErrorBoundary as default, ErrorBoundary };
