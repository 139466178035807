import { useSocket } from '@elotech/components';
import { useCallback, useEffect, useState } from 'react';

export const useAsyncUpdated = (handleUpdated, dependencies: any[] = []) => {
  const [monitoringIds, setMonitoringIds] = useState<string[]>([]);

  const addMonitor = id => {
    if (!!id && !monitoringIds.includes(id)) {
      setMonitoringIds(old => [...old, id]);
    }
  };

  useEffect(() => {
    addMonitor(window.location.href);
  }, [window.location.href]);

  useEffect(() => {
    setMonitoringIds([window.location.href]);
  }, dependencies);

  const socket = useSocket();

  const internalHandler = useCallback(
    payload => {
      let args: any[];
      try {
        args = JSON.parse(payload?.[0]) || [];
      } catch (e) {
        args = [];
      }
      setTimeout(() => handleUpdated(...args), 500);
    },
    [handleUpdated]
  );

  useEffect(() => {
    if (!socket) {
      return;
    }
    const topics = monitoringIds.map(id => `async-page-updated-${id}`);

    topics.forEach(topic => socket.on(topic, internalHandler));
    return () => {
      topics.forEach(topic => socket.off(topic, internalHandler));
    };
  }, [socket, monitoringIds, internalHandler]);
};
