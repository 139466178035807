import {
  Container,
  Loading,
  SectionTitle,
  useInterval,
  useShowNotification
} from '@elotech/components';
import { DynamicFormDataView } from '@elotech/react-components';
import { Informativo, alert } from 'common/components';
import { useLoading } from 'common/hooks';
import FileSaver from 'file-saver';
import { useEnsureProcessoIdEntity } from 'hooks/useEnsureProcessoIdEntity';
import { LABEL_PROCESSOS } from 'labels';
import ProcessoObservadorExternoContainer from 'pages/observador-externo/ProcessoObservadorExternoContainer';
import { InteressadosContainer } from 'pages/processos-novo/interessados/InteressadosContainer';
import { ModeloDadosProcesso } from 'pages/processos-novo/modelo/ModeloDadosProcesso';
import ProcessosReferenciadosTableMP from 'pages/processos-novo/processo/ProcessosReferenciadosTableMP';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import {
  ArquivoService,
  ProcessoAgrupamentoService,
  ProcessoArquivoService,
  ProcessoObservadorExternoService,
  ProcessoRelatorioService,
  ProcessoService,
  ProcessoUsuarioViewService
} from '../../service';
import { getIsLoggedSelector } from '../../state';
import { PollingDelay } from '../../types';
import AgrupamentoArquivosProcesso from '../processos-novo/agrupamentoArquivosProcesso/AgrupamentoArquivosProcesso';
import { ProcessoAndamentosContainer } from '../processos-novo/andamentos/ProcessoAndamentosContainer';
import { ProcessoArquivos } from '../processos-novo/arquivos/ProcessoArquivos';
import { ProcessoAnexo } from '../processos-novo/processo/ProcessoAnexo';
import { ProcessoAcoes } from '../processos-novo/ProcessoAcoes';
import ProcessosForbidden from '../processos-novo/ProcessosForbidden';
import { ProcessoUsuarioViewList } from '../processos-novo/ProcessoUsuarioViewList';
import { initialState } from '../processos-novo/ProcessoUtils';
import { TramitesList } from '../processos-novo/tramites/TramitesList';
import ProcessoMPCabecalho from './ProcessoMPCabecalho';

type Props = any;

const ProcessoMpViewPage: React.FC<Props> = props => {
  const history = useHistory();
  const { search } = useLocation();
  const id = useEnsureProcessoIdEntity(search);
  const [loading, setLoading] = useLoading();
  const [loadingScreen, setLoadingScreen] = useLoading();
  const [processo, setProcesso] = useState(props.processo || initialState);
  const [agruparArquivos, setAgruparArquivos] = useState(false);

  const [pollingDelay, setPollingDelay] = useState(PollingDelay.DEFAULT);
  const showNotification = useShowNotification();
  const [agrupamentoVerificado, setAgrupamentoVerificado] = useState(false);
  const [modelo, setModelo] = useState<any>(undefined);
  const [template, setTemplate] = useState('');
  const isLogged = useSelector(getIsLoggedSelector);

  useInterval(() => {
    if (isLogged && id && !agrupamentoVerificado) {
      ProcessoAgrupamentoService.verificarAgrupamentoPendente(id)
        .then(({ data }) => {
          if (
            data?.notificacao &&
            data?.status &&
            (data.status === 'ERROR' || data.status === 'SUCCESS')
          ) {
            showNotification({
              level: data.status.toLowerCase(),
              message: data.notificacao
            });
            setAgrupamentoVerificado(true);
            setLoading(getProcessoById());
          }

          if (!data?.status) {
            setAgrupamentoVerificado(true);
          }

          setPollingDelay(PollingDelay.DEFAULT);
        })
        .catch(err => {
          // eslint-disable-next-line
          console.error(`[Polling de verificação de agrupamento]: ${err}`);
          setPollingDelay(PollingDelay.ON_ERROR);
        });
    }
  }, pollingDelay);

  const showAgruparArquivos = () => {
    setLoadingScreen(
      ProcessoAgrupamentoService.existeProcessoEmAgrupamento(id)
    ).then(({ data }) => {
      if (data) {
        getProcessoById().then(() => {
          setAgruparArquivos(true);
        });
      } else {
        alert({
          type: 'warning',
          title: 'Atenção',
          text: 'Agrupamento de Arquivos em andamento para este Processo. Tente novamente em alguns instantes.'
        });
      }
    });
  };

  useEffect(() => {
    if (isLogged) {
      setLoading(
        getProcessoById().then(() =>
          ProcessoUsuarioViewService.saveUserView(id)
        )
      );
    }
  }, [id, setLoading, isLogged]);

  const getProcessoById = () => {
    return ProcessoService.findProcessoById(id).then(({ data }) =>
      setProcesso(data)
    );
  };

  const onClickImprimirPapeleta = sequencia => {
    alert({
      type: 'success',
      showCancelButton: true,
      cancelButtonText: 'Local',
      confirmButtonText: 'Lote',
      title: 'Qual agrupamento deseja utilizar?',
      onConfirm: () => onSelectAgrupamento('LOTE', sequencia),
      onCancel: () => onSelectAgrupamento('LOCAL', sequencia)
    });
  };

  const onSelectAgrupamento = (tipo, sequencia) => {
    const tramite = {
      ano: processo.ano,
      numero: processo.numero,
      tipo: processo.tipo.id,
      sequencia: sequencia || 0
    };

    return setLoadingScreen(
      ProcessoRelatorioService.createPapeletaTramite([tramite], tipo)
    );
  };

  const onUpdateParecer = tramite => {
    const index = processo.tramites.findIndex(
      t => t.sequencia === tramite.sequencia
    );

    setProcesso(prev => ({
      ...prev,
      tramites: [
        ...prev.tramites.slice(0, index),
        tramite,
        ...prev.tramites.slice(index + 1)
      ]
    }));
  };

  const onUpdateCaixa = tramite => {
    setProcesso(prev => ({
      ...prev,
      tramites: [...prev.tramites, tramite]
    }));
  };

  if (!processo?.id && !loading) {
    if (!id || id.indexOf('idsFinalizados') < 0) {
      return (
        <ProcessosForbidden message="Conforme parametrização da entidade, usuário não tem permissão a visualizar o processo. Verifique com o gestor do sistema na entidade." />
      );
    }
    setLoading(new Promise(() => {}));
  }

  const onFecharReabrirProcesso = () => {
    const possuiArquivoAgrupado =
      processo.arquivos.filter(arquivo => arquivo.agrupamento).length > 0;

    const fecharReabrirProcesso = () =>
      setLoadingScreen(
        ProcessoService.fecharReabrirProcesso(processo).then(() =>
          getProcessoById()
        )
      );

    if (possuiArquivoAgrupado) {
      alert({
        title:
          'Já foi gerado o documento final do processo e se prosseguir com o fechamento, não será possível realizar nova geração sem desfazer o processo. Deseja prosseguir?',
        type: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        onConfirm: () => fecharReabrirProcesso()
      });
      return;
    }

    return fecharReabrirProcesso();
  };

  const handleFiles = arquivos => {
    setProcesso(prev => ({ ...prev, arquivos }));
  };

  const onRealizarDownloadDeTodosAnexos = () => {
    alert({
      type: 'question',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
      title: 'Deseja que os Anexos sejam Zipados?',
      onConfirm: () => downloadAnexosInZip(processo),
      onCancel: () => downloadArquivoSeparado(processo)
    });
  };

  const onGeraProcessoReferenciado = processo => {
    history.push('/processo-mp?redirect_url=processos-novo', processo);
  };

  const downloadAnexosInZip = processo => {
    ProcessoArquivoService.downloadAnexosProcesso(processo.arquivos).then(
      response => {
        FileSaver.saveAs(
          URL.createObjectURL(response.arquivo),
          response.fileName
        );
      }
    );
  };

  const downloadArquivoSeparado = async processo => {
    await Promise.all(
      processo.arquivos.map(arquivo => ArquivoService.download(arquivo))
    );
  };

  const mostraAnexoExterno = processo => {
    return !processo.bloqueioAnexoExterno || isLogged;
  };

  return (
    <Container breadcrumb>
      <Loading loading={loadingScreen} />
      <ProcessoAcoes
        processo={processo}
        reloadProcesso={getProcessoById}
        onFecharReabrirProcesso={onFecharReabrirProcesso}
        onImprimirPapeleta={onClickImprimirPapeleta}
        onAgruparArquivos={showAgruparArquivos}
        onImprimirTodosOsAnexos={onRealizarDownloadDeTodosAnexos}
        onGeraProcessoReferenciado={onGeraProcessoReferenciado}
        onVoltar={history.goBack}
        hideButtons={
          [
            'IMPRIMIR',
            'ETIQUETA',
            'CAPA',
            'VISUALIZAR',
            'COMPROVANTE',
            'REQUERIMENTO',
            'PAPELETA'
          ] as never[]
        }
        showTramitaEntidades={false}
      />
      <SectionTitle>Informações Gerais</SectionTitle>
      <ProcessoMPCabecalho processo={processo} loading={loading} />
      {processo.solicitacaoJson && (
        <>
          <SectionTitle>Dados da Solicitação</SectionTitle>
          <DynamicFormDataView values={JSON.parse(processo.solicitacaoJson)} />
        </>
      )}
      <SectionTitle>{LABEL_PROCESSOS} Vinculados</SectionTitle>
      {isLogged && <ProcessosReferenciadosTableMP processoId={id} />}
      <SectionTitle>Trâmites</SectionTitle>
      <TramitesList
        processo={processo}
        loading={loading}
        onUpdateParecer={onUpdateParecer}
        onUpdateCaixa={onUpdateCaixa}
        tramites={processo.tramites || []}
        onImprimirPapeleta={onClickImprimirPapeleta}
        paramProcessoMp={true}
      />
      {['PADI', 'SINDICANCIA'].includes(processo?.tipo?.tipoProcessoMp) && (
        <InteressadosContainer
          interessados={processo.interessados}
          canAdd={false}
          canRemove={false}
          onAdd={undefined}
          onRemove={undefined}
          label="Comissão"
        />
      )}
      <ProcessoAndamentosContainer
        andamentos={processo.andamentos}
        processosArquivos={processo.arquivos}
        onAdd={() => getProcessoById()}
        onEdit={() => getProcessoById()}
        onRemove={() => getProcessoById()}
        canAdd={isLogged}
        processo={processo}
      />
      <ProcessoObservadorExternoContainer
        observadoresExternos={processo.observadoresExternos}
        canAddAndRemove={isLogged}
        onAdd={obs => {
          setLoadingScreen(
            ProcessoObservadorExternoService.save({ ...obs, processo }).then(
              ({ data }) => {
                setProcesso(prev => ({
                  ...prev,
                  observadoresExternos: [...prev.observadoresExternos, data]
                }));
              }
            )
          );
        }}
        onRemove={(obs, index) => {
          setLoadingScreen(
            ProcessoObservadorExternoService.remove(obs.id).then(() => {
              setProcesso(prev => ({
                ...prev,
                observadoresExternos: [
                  ...prev.observadoresExternos.slice(0, index),
                  ...prev.observadoresExternos.slice(index + 1)
                ]
              }));
            })
          );
        }}
      />
      {processo.numero && isLogged && (
        <ProcessoUsuarioViewList processo={processo} />
      )}
      {!!processo.processosAnexos?.length && (
        <>
          <SectionTitle>Processos Anexos</SectionTitle>
          <ProcessoAnexo
            loading={loading}
            anexos={processo.processosAnexos || []}
            onRemove={undefined}
          />
        </>
      )}
      {isLogged && (
        <ModeloDadosProcesso
          modelo={modelo}
          setModelo={setModelo}
          idProcesso={id}
          assuntoProcesso={processo.assunto}
          onAddFilesFromResponse={res => handleFiles(res.data.arquivos)}
          setLoading={setLoadingScreen}
          template={template}
          setTemplate={setTemplate}
          processoUuid={processo.uuid}
        />
      )}

      {!mostraAnexoExterno(processo) && (
        <Informativo type="INFO" classes="mt-xs">
          A inclusão de novos anexos está bloqueada até liberação pela análise
          interna da entidade
        </Informativo>
      )}

      <ProcessoArquivos
        isSortable
        processo={processo}
        onVisualizarArquivoHtml={(data, file) => {
          const decoder = new TextDecoder();
          const html = decoder.decode(data);
          setTemplate(html);
          file?.nome?.toLowerCase().endsWith('.html') &&
            setModelo({ nome: file.nome.replace(/.html+$/i, '') });
        }}
        onUpdateFile={() => setLoading(getProcessoById())}
        canAddArquivos={!processo.fechado && mostraAnexoExterno(processo)}
        showAgruparArquivos={showAgruparArquivos}
      />
      {agruparArquivos && (
        <AgrupamentoArquivosProcesso
          processo={processo}
          mostraCapa={false}
          onClose={() => {
            setAgrupamentoVerificado(false);
            setAgruparArquivos(false);
          }}
        />
      )}
    </Container>
  );
};

export default ProcessoMpViewPage;
