export enum DetalheConfiguracaoIntegracaoEnum {
  BLL = 'BLL',
  BNC = 'BNC',
  LICITANET = 'LICITANET',
  COMPRASNET = 'COMPRASNET',
  COMPRASBR = 'COMPRASBR',
  OUTRA = 'OUTRA',
  COMPRAS_PUBLICAS = 'COMPRAS_PUBLICAS',
  BBMNET = 'BBMNET',
  BANRISUL = 'BANRISUL',
  LICITAR = 'LICITAR'
}
