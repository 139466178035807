export enum CategoriaModeloEnum {
  LICITACAO = 'LICITACAO',
  CONTRATO = 'CONTRATO',
  ADITIVO = 'ADITIVO',
  ATA = 'ATA',
  BEM = 'BEM',
  HOMOLOGACAO = 'HOMOLOGACAO',
  PREGAO = 'PREGAO',
  ANEXO = 'ANEXO',
  PEDIDO_COMPRA = 'PEDIDO_COMPRA',
  OBRAS = 'OBRAS',
  ACOMPANHAMENTO = 'ACOMPANHAMENTO',
  ADJUDICACAO = 'ADJUDICACAO',
  PROCESSO_ADM = 'PROCESSO_ADM',
  CERTIFICADO_REGISTRO_CADASTRAL = 'CERTIFICADO_REGISTRO_CADASTRAL',
  PARECER = 'PARECER',
  MURAL = 'MURAL',
  COTACAO = 'COTACAO',
  PCA = 'PCA',
  REGULAMENTACAO = 'REGULAMENTACAO',
  CONTROLE_AMOSTRAS = 'CONTROLE_AMOSTRAS',
  AUTORIZACAO_ORDEM_FORNECIMENTO = 'AUTORIZACAO_ORDEM_FORNECIMENTO',
  TRANSFERENCIA = 'TRANSFERENCIA',
  TERMO = 'TERMO', //Utilizado no obras
  TERMOS = 'TERMOS', //Utilizado no patrimônio
  MOVIMENTO = 'MOVIMENTO',
  MANUTENCAO = 'MANUTENCAO',
  MOVIMENTACAO_CADASTRAL = 'MOVIMENTACAO_CADASTRAL',
  REQUISICAO_EXTRA = 'REQUISICAO_EXTRA',
  INTERVENCAO_MEDICAO = 'INTERVENCAO_MEDICAO'
}

export const CategoriaModeloEnumFormatado = {
  LICITACAO: 'Licitação',
  CONTRATO: 'Contrato',
  ATA: 'Ata',
  PREGAO: 'Pregão',
  BEM: 'Bem',
  TERMO: 'Termo',
  ACOMPANHAMENTO: 'Acompanhamento',
  HOMOLOGACAO: 'Homologação',
  ADITIVO: 'Aditivo',
  ANEXO: 'Anexo',
  PEDIDO_COMPRA: 'Pedido compra',
  OBRAS: 'Obras',
  ADJUDICACAO: 'Adjudicação',
  PROCESSO_ADM: 'Processo Administrativo',
  CERTIFICADO_REGISTRO_CADASTRAL: 'CRC - Certificado de Registro Cadastral',
  PARECER: 'Parecer',
  MURAL: 'Mural',
  COTACAO: 'Cotação',
  PCA: 'PCA',
  REGULAMENTACAO: 'Regulamentação',
  CONTROLE_AMOSTRAS: 'Controle de Amostras',
  AUTORIZACAO_ORDEM_FORNECIMENTO: 'Autorizações/Ordem de Fornecimento',
  TRANSFERENCIA: 'Transferência',
  TERMOS: 'Termos',
  MOVIMENTO: 'Movimentação',
  MANUTENCAO: 'Manutenção',
  MOVIMENTACAO_CADASTRAL: 'Movimentação Cadastral',
  REQUISICAO_EXTRA: 'Requisição Extra',
  INTERVENCAO_MEDICAO: 'Intervenção Medição'
};

export const CategoriaModeloEnumFilterOptions = {
  LICITACAO: 'Licitação',
  CONTRATO: 'Contrato',
  ATA: 'Ata',
  PREGAO: 'Pregão',
  BEM: 'Bem',
  TERMO: 'Termo',
  ACOMPANHAMENTO: 'Acompanhamento',
  HOMOLOGACAO: 'Homologação',
  ADITIVO: 'Aditivo',
  ANEXO: 'Anexo',
  PEDIDO_COMPRA: 'Pedido compra',
  OBRAS: 'Obras',
  ADJUDICACAO: 'Adjudicação',
  PROCESSO_ADM: 'Processo Administrativo',
  CERTIFICADO_REGISTRO_CADASTRAL: 'CRC - Certificado de Registro Cadastral',
  PARECER: 'Parecer',
  MURAL: 'Mural',
  COTACAO: 'Cotação',
  PCA: 'PCA',
  REGULAMENTACAO: 'Regulamentação',
  CONTROLE_AMOSTRAS: 'Controle de Amostras',
  AUTORIZACAO_ORDEM_FORNECIMENTO: 'Autorizações/Ordem de Fornecimento',
  TRANSFERENCIA: 'Transferência',
  TERMOS: 'Termos',
  MANUTENCAO: 'Manutenção',
  MOVIMENTACAO_CADASTRAL: 'Movimentação Cadastral',
  REQUISICAO_EXTRA: 'Requisição Extra',
  INTERVENCAO_MEDICAO: 'Intervenção Medição'
};
