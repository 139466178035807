import { useIntl } from 'react-intl';

import { formatCurrency, formatToDecimal, isNumber } from '../../../utils';

type ColSpanProps = {
  value: number;
  label?: string;
  isCurrency?: boolean;
  fractionDigits?: number;
};

type SpecificColSpan = ColSpanProps & {
  labelColSpan: number;
  valueColSpan: number;
};

type CalculatedColSpan = ColSpanProps & {
  colPosition: number;
};

type Props = SpecificColSpan | CalculatedColSpan;

const isCalculatedColSpan = (props: Props): props is CalculatedColSpan => {
  return isNumber((props as CalculatedColSpan).colPosition);
};

export const FooterValorTotal = (props: Props) => {
  const intl = useIntl();

  const renderFooter = (labelSize, valueSize) => {
    const {
      label = 'Valor total:',
      isCurrency = true,
      value,
      fractionDigits = 2
    } = props;

    return (
      <tfoot>
        <tr>
          <td colSpan={labelSize} className="right hidden-xs">
            <b>{label}</b>
          </td>
          <td colSpan={valueSize}>
            <span className="td-content">
              {isCurrency
                ? formatCurrency(value, intl, fractionDigits)
                : formatToDecimal(value, intl, fractionDigits)}
            </span>
          </td>
        </tr>
      </tfoot>
    );
  };

  const renderCaclulatedColSpan = colPosition => {
    const valueSize = Math.floor(colPosition / 3);
    const labelSize = colPosition - valueSize;
    return renderFooter(labelSize, valueSize);
  };

  return isCalculatedColSpan(props)
    ? renderCaclulatedColSpan(props.colPosition)
    : renderFooter(props.labelColSpan, props.valueColSpan);
};
