import 'pdfjs-dist/build/pdf.worker.min.mjs';

import {
  BreadCrumbProvider,
  KeycloakService,
  Loading,
  NotificationContainer,
  SidebarToggle
} from '@elotech/components';
import defaultAxios from 'axios';
import classNames from 'classnames';
import { ErrorBoundary, TransProgressContainer } from 'common/components';
import { axios } from 'libs';
import { Suspense } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { IntlProvider } from 'react-intl';
import { pdfjs } from 'react-pdf';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';

import keycloakConfig from './keycloak.json';
import { AppHeader, AppSidebar } from './menus';
import { AuthProvider, CubejsProvider } from './providers';
import { RedirectContainer, Routes, allRoutes } from './routes';
import { configureStore, rootReducer } from './state';
import { hasIFrame } from './utils';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

KeycloakService.init(keycloakConfig, false);

const locale = (navigator.languages && navigator.languages[0]) || 'pt-BR';

/* Correção temporária até que seja ajustado diretamente no Arc */
const StyledCustom = styled.div`
  .header-content {
    position: initial !important;
  }

  @media screen and (min-width: 768px) {
    .sidebar {
      min-width: 70px !important;
    }
  }
`;

const App = ({ config, configExterna }) => {
  const isEmbedded = hasIFrame();
  const DnDClient = window.innerWidth >= 768 ? HTML5Backend : TouchBackend;

  return (
    <Provider store={configureStore(rootReducer(config, configExterna))}>
      <ThemeProvider theme={{}}>
        <IntlProvider locale={locale}>
          <NotificationContainer>
            <AuthProvider>
              <CubejsProvider>
                <DndProvider backend={DnDClient}>
                  <BreadCrumbProvider routes={allRoutes}>
                    <TransProgressContainer axios={[axios, defaultAxios]} />
                    <SidebarToggle>
                      {({ toggledSidebar }) => (
                        <BrowserRouter basename={process.env.PUBLIC_URL}>
                          <div className="wrapper">
                            {!isEmbedded && (
                              <StyledCustom>
                                <AppHeader />
                                <AppSidebar />
                              </StyledCustom>
                            )}
                            <section
                              className={classNames(
                                'main-content sidebar-toggle-md',
                                {
                                  fullScreen: isEmbedded,
                                  'no-sidebar': isEmbedded,
                                  'sidebar-toggle': toggledSidebar
                                }
                              )}
                            >
                              <ErrorBoundary>
                                <Suspense fallback={<Loading loading />}>
                                  <RedirectContainer>
                                    <Routes />
                                  </RedirectContainer>
                                </Suspense>
                              </ErrorBoundary>
                            </section>
                          </div>
                        </BrowserRouter>
                      )}
                    </SidebarToggle>
                  </BreadCrumbProvider>
                </DndProvider>
              </CubejsProvider>
            </AuthProvider>
          </NotificationContainer>
        </IntlProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
