import { FilterUtils } from '@elotech/components';
import { isNumber } from 'common/utils';
import { axios } from 'libs';

const resource = '/pessoas';
const defaultSort = { sort: 'id,asc' };

export const findAllRequerentes = (search = '', pagination) => {
  return axios.get(`${resource}/dto`, {
    params: {
      search,
      ...pagination
    }
  });
};

export const findAllAutocomplete = (search = '', pagination) => {
  const filter = isNumber(search)
    ? `cnpjCpf=="*${search}*" or id=="${search}"`
    : FilterUtils.buildFilter(['nome'])(search);

  return axios.get(resource, {
    params: {
      search: filter,
      ...pagination
    }
  });
};

export const possuiDebitos = cnpjCpf => {
  return axios.get(`${resource}/debitos/exists?cnpjCpf=${cnpjCpf}`);
};

export const findAllLogs = id => {
  return axios.get(`${resource}/${id}/history`);
};

export const findByCnpjCpf = cnpjCpf => {
  return axios.get(`${resource}/consultapessoa/${cnpjCpf}`);
};

export const findAllAtualizacoes = () => {
  return axios.get(`${resource}/atualizacoes`);
};

export const pessoasDuplicadas = cnpjCpf => {
  return axios.get(`${resource}/duplicados/${cnpjCpf}`);
};

export const findAll = (search, pagination, sort = defaultSort) => {
  return axios.get(resource, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const deleteById = id => {
  return axios.delete(`${resource}/${id}`);
};

export const findById = id => {
  return axios.get(`${resource}/${id}`);
};

export const atualizarPessoa = (id, idAtualizador) => {
  return axios.post(
    `${resource}/atualizar/pessoa/${id}/atualizador/${idAtualizador}`
  );
};

export const getAtualizacoes = cnpjCpf => {
  return axios.get(`${resource}/atualizacao/cpf/${cnpjCpf}`);
};

export const save = pessoa => {
  return pessoa.id
    ? axios.put(`${resource}/${pessoa.id}`, pessoa)
    : axios.post(resource, pessoa);
};
