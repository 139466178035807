import { Col } from '@elotech/components';
import { InlineButton } from 'common/components';

export const PdfPagination = ({ totalPages, page, setPage }) => {
  const handleNextPage = () => {
    const nextPage = Math.min(page + 1, totalPages);
    setPage(nextPage);
  };
  const handlePreviousPage = () => {
    const previousPage = Math.max(page - 1, 1);
    setPage(previousPage);
  };
  return (
    <>
      <Col className="mt-xs center" md={1} xs={1}>
        <InlineButton
          disabled={page === 1}
          onClick={handlePreviousPage}
          icon="fa fa-chevron-left"
        />
      </Col>
      <Col className="mt-xs center" md={3} xs={3}>
        Página:
        <input
          type="number"
          min={1}
          max={totalPages}
          value={page}
          style={{ width: '5ch', boxSizing: 'content-box', display: 'inline' }}
          onChange={e => setPage(parseInt(e.target.value || page))}
        />
        /{totalPages}
      </Col>
      <Col className="mt-xs center" md={1} xs={1}>
        <InlineButton
          disabled={page === totalPages}
          onClick={handleNextPage}
          className="icon-right"
          icon="fa fa-chevron-right"
        />
      </Col>
    </>
  );
};
