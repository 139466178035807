import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import { Col, Loading, Modal, Panel, Row } from '@elotech/components';
import { InlineButton } from 'common/components';
import { SILENT_REJECTION } from 'common/components/error/ErrorBoundary';
import { useLoading } from 'common/hooks';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux';
import {
  ArquivoAssinaturaService,
  ArquivoService,
  AssinaturaConfiguracaoService
} from 'service';
import { getIsLoggedSelector } from 'state';

import { Assinatura } from './Assinatura';
import { PdfPagination } from './PdfPagination';

const AssinaturaPosicionamentoContext = createContext(() =>
  Promise.resolve({
    x: 0,
    y: 0,
    page: 1,
    ratio: 1
  })
);

export const useAssinaturaPosicionamentoContext = () =>
  useContext(AssinaturaPosicionamentoContext);

export const AssinaturaPosicionamento = ({ children }) => {
  const [assinatura, setAssinatura] = useState();
  const [loading, setLoading] = useLoading();
  const [arquivo, setArquivo] = useState();
  const [showAssinatura, setShowAssinatura] = useState(false);
  const [rejecter, setRejecter] = useState();
  const [resolver, setResolver] = useState();
  const [configuracao, setConfiguracao] = useState();
  const isLogged = useSelector(getIsLoggedSelector);

  useEffect(() => {
    if (!isLogged) {
      return;
    }
    AssinaturaConfiguracaoService.findByEntidadeLogada().then(({ data }) => {
      const carimboData = data?.carimboArquivo?.content?.binary;
      const mimeType = data?.carimboArquivo?.content?.mimeType;

      data.carimboUrl =
        mimeType && carimboData && `data:${mimeType};base64,${carimboData}`;

      setConfiguracao(data);
    });
  }, [isLogged]);

  const rejectAll = useCallback(() => {
    setResolver(null);
    setAssinatura(null);
    setArquivo(null);
    if (rejecter) {
      rejecter(SILENT_REJECTION);
      setRejecter(null);
    }
  }, [setResolver, setAssinatura, setArquivo, rejecter, setRejecter]);

  const handleAssinatura = arquivo =>
    new Promise((resolve, reject) => {
      const init = () => {
        setAssinatura(arquivo);
        setResolver(() => resolve);
        setRejecter(() => reject);
      };

      if (
        arquivo.obrigaPosicionamentoAssinatura ||
        configuracao?.posicionaAssinatura
      ) {
        init();
        return;
      }

      setLoading(
        ArquivoAssinaturaService.utilizaAssinaturaPosicional(
          arquivo.identificador
        ).then(({ data }) => {
          if (data) {
            init();
            return;
          }
          resolve(null);
        })
      );
    });

  //Limpa o BLOB da memória
  useEffect(() => {
    const oldArquivo = arquivo?.url;
    if (oldArquivo) {
      return () => {
        try {
          URL.revokeObjectURL(oldArquivo);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Falha ao liberar arquivo da memória', error);
        }
      };
    }
  }, [arquivo]);

  useEffect(() => {
    if (!assinatura?.identificador) {
      setArquivo(null);
      return;
    }
    let cancel = false;
    setLoading(
      ArquivoService.rawDownload(assinatura).then(({ data }) => {
        if (cancel) {
          return;
        }
        const blob = new Blob([data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        setArquivo({ x: 0, y: 0, page: 1, ratio: 1, url });
      })
    );

    return () => {
      cancel = true;
    };
  }, [assinatura]);

  const handleLoadedPdf = pdf => {
    setArquivo({
      ...arquivo,
      ratio: 1,
      page: pdf.numPages,
      pages: pdf.numPages
    });
  };

  const handleLoadedPage = page => {
    setArquivo({
      ...arquivo,
      pageRatio: page.width / page.originalWidth
    });
    setShowAssinatura(true);
  };

  const sign = useCallback(() => {
    const { x, y, page, ratio, pageRatio } = arquivo;
    setAssinatura(null);
    resolver?.({
      x: x / pageRatio,
      y: y / pageRatio,
      page,
      ratio
    });
  }, [resolver, arquivo, setAssinatura]);

  return (
    <>
      <Loading loading={loading} />
      <AssinaturaPosicionamentoContext.Provider value={handleAssinatura}>
        {!!arquivo && (
          <Modal onClose={rejectAll}>
            <Panel title={`Assinando arquivo ${assinatura?.nome}`}>
              <Row>
                <PdfPagination
                  totalPages={arquivo.pages}
                  page={arquivo.page}
                  setPage={page => setArquivo({ ...arquivo, page })}
                />
                <Col
                  md={5}
                  xs={5}
                  className="mt-xs"
                  style={{ display: 'flex' }}
                >
                  Escala
                  <input
                    type="range"
                    min={0.5}
                    max={1}
                    step={0.1}
                    className="inline ml-xs"
                    value={arquivo.ratio}
                    onChange={e =>
                      setArquivo({ ...arquivo, ratio: e.target.value })
                    }
                  />
                </Col>
                <Col md={2} xs={2}>
                  <InlineButton
                    onClick={sign}
                    icon="fa fa-signature"
                    label="Assinar"
                    className="mt-xs"
                  />
                </Col>
              </Row>
              <div
                style={{
                  position: 'relative',
                  width: '500px',
                  margin: 'auto'
                }}
              >
                <Document
                  loading="Carregando PDF..."
                  noData="Nenhum PDF encontrado"
                  error="Erro ao carregar PDF"
                  className="center"
                  file={arquivo.url}
                  onLoadStart={() => setShowAssinatura(false)}
                  onLoadSuccess={handleLoadedPdf}
                  onLoadError={() => setShowAssinatura(false)}
                >
                  <Page
                    renderAnnotationLayer
                    pageNumber={arquivo.page}
                    width={500}
                    onLoadStart={() => setShowAssinatura(false)}
                    onLoadError={() => setShowAssinatura(false)}
                    onLoadSuccess={handleLoadedPage}
                    error="Falha ao carregar a página"
                  >
                    <Assinatura
                      setArquivo={setArquivo}
                      hidden={!showAssinatura}
                      ratio={arquivo.ratio}
                      pageRatio={arquivo.pageRatio}
                      configuracao={configuracao}
                    />
                  </Page>
                </Document>
              </div>
            </Panel>
          </Modal>
        )}
        {children}
      </AssinaturaPosicionamentoContext.Provider>
    </>
  );
};
