import {
  ActionButton,
  Loading,
  useShowNotification
} from '@elotech/components';
import { alert } from 'common/components';
import { useLoading } from 'common/hooks';
import { useFreshConfig } from 'hooks';
import { LABEL_ROTINA_INDISP } from 'labels';
import { ArquivoAssinaturaService } from 'service';
import { isUtilizaAssinatura, isUtilizaAssinaturaDigital } from 'utils';

import { useAssinaturaPosicionamentoContext } from './AssinaturaPosicionamento';

const defaultValidate = (_, assinar) => assinar();

const AssinaturaActionButton = ({
  assinatura,
  onUpdateFile,
  validateAssinar = defaultValidate
}) => {
  const [config, loadingConfig, refreshConfig] = useFreshConfig();
  const showNotification = useShowNotification();
  const [loading, setLoading] = useLoading();
  const handleAssinatura = useAssinaturaPosicionamentoContext();

  const validaPermissaoAssinaturaEletronica = async (handleClick, param) => {
    refreshConfig(freshConfig => {
      if (!freshConfig) return;

      if (!isUtilizaAssinatura(freshConfig)) {
        return showNotification({
          level: 'error',
          message: LABEL_ROTINA_INDISP
        });
      }
      validateAssinar(assinatura, () => handleClick(param));
    });
  };

  const validaPermissaoAssinaturaDigital = async (handleClick, param) => {
    refreshConfig(freshConfig => {
      if (!freshConfig) return;

      if (isUtilizaAssinaturaDigital(freshConfig)) {
        validateAssinar(assinatura, () => handleClick(param));
      } else {
        return showNotification({
          level: 'error',
          message: LABEL_ROTINA_INDISP
        });
      }
    });
  };

  const onRejeitarAssinatura = () => {
    alert({
      type: 'warning',
      title: 'Rejeitar',
      input: 'textarea',
      showCancelButton: true,
      cancelButtonColor: '#c7382d',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Rejeitar',
      onConfirm: motivo =>
        ArquivoAssinaturaService.rejeitarAssinatura(
          assinatura.identificador,
          motivo
        ).then(() => onUpdateFile()),
      text: 'Deseja mesmo rejeitar a assinatura?',
      inputPlaceholder: 'Informe o motivo da rejeição',
      inputValidator: value => {
        return new Promise(resolve => {
          if (!value) {
            return resolve('Informe o motivo de estar recusando a assinatura.');
          }
          resolve(false);
        });
      }
    });
  };

  const onAssinarDigital = () => {
    if (assinatura?.id) {
      ArquivoAssinaturaService.runSigner(
        assinatura.id,
        assinatura.urlS3,
        config.tenant
      );
    } else {
      ArquivoAssinaturaService.solicitarAssinaturaDigital(
        assinatura.identificador
      ).then(({ data }) => {
        ArquivoAssinaturaService.runSigner(
          data.id,
          assinatura.urlS3,
          config.tenant
        );
      });
    }
  };

  const onAssinarEletronicaClick = async () => {
    const posicao = await handleAssinatura(assinatura);

    alert({
      title: 'Autenticação',
      input: 'password',
      type: 'question',
      text: 'Para assinar o documento é necessário realizar a autenticação.',
      inputPlaceholder: 'Digite sua senha',
      showCancelButton: true,
      confirmButtonText: 'Autenticar',
      cancelButtonText: 'Cancelar',
      onConfirm: async senha => {
        await setLoading(
          ArquivoAssinaturaService.assinar({
            identificador: assinatura.identificador,
            senha,
            posicao
          }).then(() => onUpdateFile())
        );
      },
      inputValidator: value => {
        return new Promise(resolve => {
          if (value) {
            resolve();
          } else {
            resolve('Digite a senha.');
          }
        });
      }
    });
  };

  return (
    <>
      <Loading loading={loading || loadingConfig} />
      <ActionButton
        icon="signature"
        links={[
          {
            label: 'Assinatura Eletrônica',
            onClick: () =>
              validaPermissaoAssinaturaEletronica(onAssinarEletronicaClick)
          },
          {
            label: 'Assinatura Digital',
            onClick: () => validaPermissaoAssinaturaDigital(onAssinarDigital)
          },
          {
            label: 'Rejeitar',
            onClick: () => onRejeitarAssinatura()
          }
        ]}
      />
    </>
  );
};

export default AssinaturaActionButton;
