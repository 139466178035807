import { isNumber } from 'common/utils';
import { axios } from 'libs';
import { ORDENA_PROCESSOS_PELA_DATA_CRIACAO } from 'pages/parametros/Parametros';
import { ParametroService, ProcessoArquivoService } from 'service';

const resource = '/processos';

const defaultSort = {
  sort: 'ultimaAtualizacao,desc'
};

export const save = processo => {
  if (processo.id) {
    return axios.put(`${resource}${processo.id}`, processo);
  }

  return axios.post(resource, processo);
};

export const getProcessos = (search, pagination, sort = defaultSort) => {
  return ParametroService.findParametro(
    ORDENA_PROCESSOS_PELA_DATA_CRIACAO
  ).then(({ data }) => {
    if (data?.valor === 'S' && sort?.sort === defaultSort?.sort) {
      sort = { sort: 'dataProcesso,id.ano,id.numero,desc' };
    }

    return axios.get(`${resource}/lista`, {
      params: {
        search,
        ...pagination,
        ...sort
      }
    });
  });
};

export const findProcessoById = id => {
  return axios.get(`${resource}${id}`);
};

export const template = () => {
  return axios.get(`${resource}/template`);
};

export const avaliarProcesso = processo => {
  const params = {
    tipo: processo.tipo.id,
    ano: processo.ano,
    numero: processo.numero,
    entidade: processo.entidade.id
  };

  return axios.post(`${resource}/avaliacao`, params);
};

export const findAllLogs = id => {
  return axios.get(`${resource}/history${id}`);
};

export const toggleStatusParalisado = processo => {
  const { numero, ano, tipo, entidade } = processo;

  return axios.put(
    `${resource}/alterar-paralisado?tipoProcesso=${tipo.id}&anoProcesso=${ano}&numeroProcesso=${numero}&entidadeProcesso=${entidade.id}`
  );
};

export const verificarSeJaExisteProcesso = (idAssunto, idPessoa) => {
  return axios.get(`${resource}/pesquisa-processo-assunto`, {
    params: {
      idAssunto,
      idPessoa
    }
  });
};

export const fecharReabrirProcesso = processo => {
  return axios.put(`/processos/fechar-reabrir-processo${processo.id}`);
};

const tramitarProcessos = processo => {
  return axios.post('/processos/tramitar', processo);
};

export const receberProcessos = processo =>
  tramitarProcessos({ ...processo, funcao: 'Recebido' });

export const encaminharProcessos = processo =>
  tramitarProcessos({ ...processo, funcao: 'Encaminhado' });

export const arquivarProcessos = processo =>
  tramitarProcessos({ ...processo, funcao: 'Arquivado' });

export const reabrirProcessos = processo =>
  tramitarProcessos({ ...processo, funcao: 'Encaminhado' });

export const findAllListaRecebimento = (
  search,
  pagination,
  sort = defaultSort,
  filtrarResponsavelTramite = false
) => {
  return axios.get(`${resource}/lista-recebimento`, {
    params: {
      search,
      filtrarResponsavelTramite,
      ...pagination,
      ...sort
    }
  });
};

export const findAllListaEncaminhamento = (
  search,
  pagination,
  sort = defaultSort,
  filtrarResponsavelTramite = false
) => {
  return axios.get(`${resource}/lista-encaminhamento`, {
    params: {
      search,
      filtrarResponsavelTramite,
      ...pagination,
      ...sort
    }
  });
};

export const findAllListaFavoritos = (
  search,
  pagination,
  sort = defaultSort
) => {
  return axios.get(`${resource}/lista-favoritos`, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const findAllListaArquivamento = (
  search,
  pagination,
  sort = defaultSort
) => {
  return axios.get(`${resource}/lista-arquivamento`, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const findAllListaReabertura = (
  search,
  pagination,
  sort = defaultSort
) => {
  return axios.get(`${resource}/lista-arquivado`, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const findAllListaVencidos = (
  search,
  pagination,
  sort = defaultSort
) => {
  return axios.get(`${resource}/lista-vencidos`, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const findAllListaAVencer = (search, pagination, sort = defaultSort) => {
  return axios.get(`${resource}/lista-a-vencer`, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const findAllListaDentroPrazo = (
  search,
  pagination,
  sort = defaultSort
) => {
  return axios.get(`${resource}/lista-dentro-prazo`, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const findAllAutocomplete = (
  search = '',
  pagination,
  sort = defaultSort
) => {
  const filter = isNumber(search)
    ? `id.numero==${search},id.ano==${search}`
    : '';

  return axios.get(resource, {
    params: {
      search: filter,
      ...sort,
      ...pagination
    }
  });
};

export const contagemProcessos = params => {
  return axios.post(`${resource}/contagem-processos`, params);
};

export const findByLote = lote => {
  return axios.get(`${resource}/lote/${lote}`);
};

export const gerarCorrecaoFluxoProcessos = (processos, parecer) => {
  return axios.post(`${resource}/geracorrecaofluxo`, {
    processos,
    parecer
  });
};

export const gerarCorrecaoFluxoLote = (lote, parecer) => {
  return axios.post(`${resource}/geracorrecaofluxo/${lote}`, { parecer });
};

export const loadProcessosPessoa = (id, size = 10) => {
  return axios.get(`${resource}/pessoa/${id}`, {
    params: {
      size
    }
  });
};

export const listaAlteracaoRequerimento = idProcesso => {
  return axios.get(`${resource}/lista-alteracao-requerimento${idProcesso}`);
};

export const listaAlteracaoObservacao = idProcesso => {
  return axios.get(`${resource}/lista-alteracao-observacao${idProcesso}`);
};

export const findAllListaEncaminhamentoParalisado = (
  search,
  pagination,
  sort = defaultSort
) => {
  return axios.get(`${resource}/lista-encaminhamento-paralisado`, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const atualizarAssunto = (processo, assunto) => {
  return axios.put(`${resource}/atualizar-assunto${processo.id}`, assunto);
};

export const alterarTramiteEntreEntidades = idProcesso => {
  return axios.put(`${resource}/alterar-tramite-entre-entidades${idProcesso}`);
};

export const findAllProcessoArquivadoAutocomplete = (
  search = '',
  pagination,
  sort = defaultSort
) => {
  const filter = isNumber(search)
    ? `id.numero==${search},id.ano==${search}`
    : '';

  return axios.get(`${resource}/buscar-processo-arquivado`, {
    params: {
      search: filter,
      ...sort,
      ...pagination
    }
  });
};

export const saveWithFiles = (processo, files, isFileCloud) => {
  return Promise.all(
    files.map(file =>
      ProcessoArquivoService.fileToArquivoDocumento(file, isFileCloud)
    )
  ).then(arquivos =>
    axios.post(`${resource}/criar-processo-com-arquivos`, {
      processo,
      arquivos
    })
  );
};
