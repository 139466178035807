import { useAuth as theirUseAuth } from '@elotech/components';

export const useAuth = <T>() => {
  const { hasRole, ...rest } = theirUseAuth();

  return {
    hasRole: hasRole as (role: T) => boolean,
    ...rest
  };
};
