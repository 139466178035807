import { BasicInput } from '@elotech/components';
import React from 'react';

import { TinyMCE } from './TinyMCE';

type BasicInputProps = React.ComponentProps<typeof BasicInput>;
export const FormikTinyMCE: React.FC<BasicInputProps> = props => {
  return (
    <BasicInput
      {...props}
      render={({ field, form }) => (
        <TinyMCE
          editorId={field.id}
          value={field.value}
          onChange={newValue =>
            field.onChange({
              target: {
                name: field.name,
                value: newValue
              }
            } as React.ChangeEvent<any>)
          }
          disabled={field.disabled}
        />
      )}
    />
  );
};

export default FormikTinyMCE;
