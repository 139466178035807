import { useAuth } from 'common/hooks';
import { formataCpfOuCnpj } from 'common/utils';
import { useCallback, useMemo } from 'react';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';
import { getEntidadeSelector } from 'state';

const annotationFontSize = 8;
const contentFontSize = 10;
const curva = 15;
const margemTexto = 3;
const borda = annotationFontSize / 2;
const linha = 20;
const contentX = curva - margemTexto;

const AnnotationText = ({ children, isUpper }) => {
  return (
    <p
      style={{
        fontWeight: 'bold',
        position: 'absolute',
        [isUpper ? 'top' : 'bottom']: '0',
        left: `${curva + linha + margemTexto}px`,
        lineHeight: `${annotationFontSize}px`,
        fontSize: `${annotationFontSize}px`,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {children}
    </p>
  );
};

export const Assinatura = ({
  ratio,
  pageRatio,
  setArquivo,
  hidden,
  configuracao
}) => {
  const auth = useAuth();
  const { nome: entidade } = useSelector(getEntidadeSelector);

  const nome = useMemo(
    () =>
      auth.user?.nomeCompleto ||
      auth.userInfo?.nome ||
      auth.userInfo?.name ||
      'Seu nome',
    [auth]
  );
  const cpfCnpj = useMemo(() => {
    const usuario = auth.user?.cpf || auth.userInfo.username || 'Seu CPF';
    return usuario.match(/^\d+$/) ? formataCpfOuCnpj(usuario) : usuario;
  }, [auth]);

  const data = useMemo(() => {
    if (!configuracao) {
      return null;
    }
    const data = new Date();
    const dataStr = [];
    if (configuracao.showData) {
      dataStr.push(data.toLocaleDateString());
    }
    if (configuracao.showHora) {
      dataStr.push(data.toLocaleTimeString());
    }
    return dataStr.join(' ');
  }, [configuracao]);

  const largura = useMemo(
    () =>
      contentX +
      Math.max(nome.length, cpfCnpj.length, entidade.length) *
        contentFontSize *
        0.6,
    [entidade, nome, cpfCnpj]
  );

  const altura = useMemo(
    () =>
      2 * (borda + margemTexto) + 3 * contentFontSize + 2 * annotationFontSize,
    []
  );

  const setPosicao = useCallback(
    (_, { x, y }) => {
      setArquivo(arquivo => ({ ...arquivo, x, y }));
    },
    [setArquivo]
  );

  return hidden || !configuracao ? null : (
    <Draggable onStop={setPosicao} bounds="parent">
      <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          zIndex: 1000,
          cursor: 'move',
          width: `${largura * ratio * pageRatio}px`,
          height: `${altura * ratio * pageRatio}px`,
          '--text-color': 'black'
        }}
      >
        <div
          style={{
            position: 'relative',
            boxSizing: 'content-box',
            width: largura,
            height: altura,
            textAlign: 'left',
            whiteSpace: 'nowrap',
            fontFamily: 'Arial',
            textRendering: 'optimizeLegibility',
            transform: `scale(${ratio * pageRatio})`,
            transformOrigin: 'top left'
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              zIndex: '1001'
            }}
          >
            &nbsp;
          </div>
          <svg
            style={{
              position: 'absolute',
              left: '0',
              top: '0'
            }}
            width="50"
            height={altura + 2 * borda}
            viewBox={`0 0 50 ${altura + 2 * borda}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d={`M ${curva + linha} ${borda}
                  L ${curva} ${borda}
                  A ${curva} ${curva} 0 0 0 2 ${curva}
                  L 2 ${altura - curva - borda}
                  A ${curva} ${curva} 0 0 0 ${curva} ${altura - borda}
                  L ${curva + linha} ${altura - borda}
           `}
              fill="none"
              stroke="black"
              strokeWidth="1"
            />
          </svg>
          <AnnotationText isUpper={true}>Assinado por:</AnnotationText>
          <div
            style={{
              position: 'absolute',
              top: `${borda + margemTexto}px`,
              left: `${contentX}px`,
              fontSize: `${contentFontSize}px`
            }}
          >
            <p>{entidade}</p>
            <p>{nome}</p>
            <p>{!!configuracao.showCpf ? cpfCnpj : 'seu-email@servidor'}</p>
          </div>

          <AnnotationText isUpper={false}>
            {!!configuracao.carimboUrl && (
              <img
                style={{
                  height: `${2 * annotationFontSize}px`,
                  marginBottom: `-${annotationFontSize / 2}px`,
                  marginRight: `${margemTexto}px`
                }}
                src={configuracao.carimboUrl}
              ></img>
            )}
            {data}
          </AnnotationText>
        </div>
      </div>
    </Draggable>
  );
};
