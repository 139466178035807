import defaultAxios from 'axios';
import {
  TransProgressKind,
  transientRequest,
  useTransProgress
} from 'common/components';
import { getContentType } from 'common/utils';
import { saveAs } from 'file-saver';
import { axios } from 'libs';

const resource = '/arquivos';

const getUrlDownload = (fileName, fullPath, fileId) => {
  function utf8Encode(unicodeString) {
    if (typeof unicodeString !== 'string') {
      return encodeURIComponent(unicodeString);
    }

    const utf8String = unicodeString
      .replace(/[\u0080-\u07ff]/g, c => {
        const cc = c.charCodeAt(0);
        return String.fromCharCode(0xc0 | (cc >> 6), 0x80 | (cc & 0x3f));
      })
      .replace(/[\u0800-\uffff]/g, c => {
        const cc = c.charCodeAt(0);
        return String.fromCharCode(
          0xe0 | (cc >> 12),
          0x80 | ((cc >> 6) & 0x3f),
          0x80 | (cc & 0x3f)
        );
      });
    return encodeURIComponent(utf8String);
  }
  return axios.get(
    `${resource}/cloud/url/download?fullPath=${fullPath}&fileName=${utf8Encode(
      fileName
    )}`,
    transientRequest({
      fileId
    })
  );
};

export const getUrlUpload = (fileName, contentType, fileId) => {
  return axios.get(
    `${resource}/cloud/url/upload?fileName=${encodeURIComponent(
      fileName
    )}&contentType=${contentType}`,
    transientRequest({
      fileId
    })
  );
};

export const download = async file => {
  const { nome } = file;
  return await rawDownload(file).then(response => {
    const searchName =
      response?.headers?.['content-disposition']?.split('filename="')?.[1];
    const fileName = searchName?.replace('"', '') || nome;
    const type =
      getContentType(fileName, '') ||
      response.data.type ||
      response?.headers['content-type'];

    return saveAs(
      new Blob([response.data], {
        type: type
      }),
      fileName
    );
  });
};

export const valida = ({ identificador }) => {
  return axios.get(`${resource}/${identificador}/valida`, {
    responseType: 'blob'
  });
};

export const remove = (idProcesso, idArquivo) => {
  return axios.delete(`${resource}/${idArquivo}${idProcesso}`);
};

export const preview = file => {
  const { preview } = file;
  if (preview) {
    if (!window.open(preview)) {
      throw new Error('Sem permissão para mostrar popups');
    }
    return Promise.resolve();
  }

  return rawDownload(file).then(({ data, headers }) => {
    const contentDisposition = headers?.['Content-Disposition'];
    const responseFileName = contentDisposition?.substring(
      contentDisposition.indexOf('"') + 1,
      contentDisposition.lastIndexOf('"')
    );

    const type = getContentType(responseFileName || file.nome);
    const blob = new Blob([data], { type });

    setTimeout(() => {
      const url = URL.createObjectURL(blob);
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 6e4);

      setTimeout(() => {
        if (!window.open(url)) {
          throw new Error('Sem permissão para mostrar popups');
        }
      }, 200);
    }, 50);
  });
};

export const rawDownload = async (
  { nome, identificador, urlS3 },
  silent = false
) => {
  const defaultConfigs = {
    responseType: 'arraybuffer'
  };
  const configs = silent
    ? defaultConfigs
    : useTransProgress(nome, TransProgressKind.DOWNLOAD, defaultConfigs);
  let url = `${resource}/${identificador}/download`;
  let cloud = false;
  if (urlS3) {
    const cloudUrl = await getUrlDownload(nome, urlS3, configs.fileId);
    if (cloudUrl?.data?.urlAssinada) {
      cloud = true;
      url = cloudUrl.data.urlAssinada;
    }
  }
  return await (cloud ? defaultAxios : axios).get(url, configs);
};

export const editarNomeArquivo = arquivoAtualizarNomeDTO => {
  return axios.put(`${resource}/arquivo-editar-nome/`, arquivoAtualizarNomeDTO);
};
