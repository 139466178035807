import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigActions, getConfigSelector, getEntidadeIdSelector } from 'state';
import { Config } from 'types';

export const useFreshConfig = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const config = useSelector(getConfigSelector) as Config;

  const entidadeId = useSelector(getEntidadeIdSelector) as number;

  const refresh = (callbackFn?: (data?: Config) => void) => {
    setLoading(true);
    return dispatch(
      ConfigActions.refreshConfig(entidadeId, data => {
        setLoading(false);
        callbackFn?.(data);
      })
    );
  };

  useEffect(() => {
    refresh();
  }, []);

  return [config, loading, refresh] as const;
};
