export const formatTelefone = value => {
  if (!value) {
    return '';
  }

  value = value.replace(/\D/g, '');

  if (value.length > 9) {
    value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
  }
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');

  return value;
};

export const retirarFormatacaoTelefone = value => {
  if (!value) {
    return '';
  }

  return value.replace(/\D/g, '');
};
