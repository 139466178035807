import {
  Checkbox,
  Col,
  Direction,
  FAB,
  Field,
  Loading,
  PageRequest,
  PagedResponse,
  Row,
  SearchFilter,
  SearchPagination,
  Sort,
  Table,
  usePagedQuery
} from '@elotech/components';
import { AxiosPromise } from 'axios';
import {
  ComponentType,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { useLoading } from '../../hooks';
import { InlineButton } from '../inline-button/InlineButton';
import { TableContainer } from '../table';

type ContainerItem = {
  id?: string;
  selected?: boolean;
};

type SearchFilterProps = {
  onSearch: (search: string) => void;
};

export type ContainerItemsActions<T> = {
  onBeforeSet?: (selectedItems: T[]) => T[];
  removeItemsFromState: () => (dispatch) => void;
  setItemsOnState: (selectedItems: T[]) => (dispatch) => void;
};

export type ContainerItemsSearch<T> = (
  search: string,
  pagination?: PageRequest,
  sort?: Sort
) => AxiosPromise<PagedResponse<T>> | Promise<any>;

export type ContainerTableColumn<T> = {
  [index: string]: {
    sortable?: boolean;
    name?: string;
    header: string | ReactNode;
    value: (value: T, index: number) => ReactNode;
  };
};

export type ContainerItemsProps<T> = {
  additionalFilterComponent?: ReactNode;
  actions: ContainerItemsActions<T>;
  tableColumns: ContainerTableColumn<T>;
  searchFields: Field[];
  uniqueKey?: string;
  defaultSort?: {
    name: string;
    direction: Direction;
  };
  SearchFilterComponent?: ComponentType<SearchFilterProps>;
  search: ContainerItemsSearch<T>;
  onAddAllItems?: () => Promise<any>;
  keyExtractor?: (item: T, index?: number) => string;
  showItemCheckbox?: (item: T) => boolean;
  compareKeys?: (i1: T, i2: T) => boolean;
  columnSelector?: any;
  onlyOneItem?: boolean;
  highlight?: any;
};

type LocationState<T> = T & {
  excluirIds: any[];
};

export const ContainerItems = <T extends ContainerItem>(
  props: ContainerItemsProps<T>
) => {
  const {
    actions,
    tableColumns,
    searchFields,
    defaultSort,
    uniqueKey = 'id',
    additionalFilterComponent,
    SearchFilterComponent,
    search,
    onAddAllItems,
    keyExtractor = (item: T) => `${item[uniqueKey]}`,
    showItemCheckbox = (item: T) => true,
    compareKeys = (i1: T, i2: T) => i1[uniqueKey] === i2[uniqueKey],
    columnSelector,
    onlyOneItem = false,
    highlight
  } = props;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useLoading();
  const history = useHistory<LocationState<T>>();
  const locationRef = useRef<LocationState<T>>(history.location.state);
  const { loading, values, pagination, doSearch, doPagedSearch, doSortChange } =
    usePagedQuery({
      search
    });
  const [items, setItems] = useState<T[]>([]);
  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const checkIdsToHide = useCallback(
    (items: T[]): T[] => {
      const ids = locationRef.current?.excluirIds?.map(id => `${id}`) || [];

      if (ids.length) {
        return items.filter(item => !ids.includes(keyExtractor(item)));
      }

      return items;
    },
    [history, uniqueKey]
  );

  useEffect(() => {
    dispatch(actions.removeItemsFromState());
  }, [dispatch, actions]);

  useEffect(() => {
    const checkedItems = values.map(item => {
      const found = selectedItems.find(compareKeysItem(item));
      return found ? found : { ...item, selected: false };
    });
    const finalItems = checkIdsToHide(checkedItems);

    setItems(finalItems);
    setIsAllSelected(isAllItemsSelected(finalItems));
  }, [values, selectedItems, checkIdsToHide, uniqueKey]);

  const redirectUrl =
    new URLSearchParams(history.location.search).get('redirect_url') || '';

  const isAllItemsSelected = (items): boolean =>
    items
      .filter((item: T) => showItemCheckbox(item))
      .every(item => item.selected);

  const compareKeysItem = (i1: T) => (i2: T) => compareKeys(i1, i2);

  const selectItem = (event, item: T, index: number): void => {
    const found = selectedItems.find(compareKeysItem(item));

    const isNew = event.currentTarget.checked && !found;
    const newSelectedItem = { ...item, selected: event.currentTarget.checked };
    const newItems: T[] = [
      ...items.slice(0, index),
      newSelectedItem,
      ...items.slice(index + 1)
    ];

    setItems(newItems);

    setIsAllSelected(isAllItemsSelected(newItems));

    if (isNew) {
      setSelectedItems(prevItems => [...prevItems, newSelectedItem]);
    } else {
      removeFromSelected(newSelectedItem);
    }

    if (onlyOneItem) {
      const newSelectedItems = [...selectedItems, newSelectedItem];
      const itemsToApply =
        actions.onBeforeSet?.(newSelectedItems) ?? newSelectedItems;

      dispatch(actions.setItemsOnState(itemsToApply));
      history.push(redirectUrl, locationRef.current);
    }
  };

  const removeFromSelected = (selectedItem: T): void => {
    setSelectedItems(prev => {
      const index = prev.findIndex(compareKeysItem(selectedItem));
      return [...prev.slice(0, index), ...prev.slice(index + 1)];
    });
  };

  const selectAllItems = (event): void => {
    if (!items.length) {
      return;
    }

    const checked = event.currentTarget.checked;
    const selectedIds = selectedItems.map(keyExtractor);
    const pageItems = items
      .filter((item: T) => showItemCheckbox(item))
      .map((item: T) => ({ ...item, selected: checked }));

    let newItems: T[] = [];

    if (checked) {
      newItems = [
        ...selectedItems,
        ...pageItems.filter(
          (item: T) => !selectedIds.includes(keyExtractor(item))
        )
      ];
    } else {
      const unselectedIds = pageItems
        .filter(item => !item.selected)
        .map(keyExtractor);
      newItems = selectedItems.filter(
        (item: T) => !unselectedIds.includes(keyExtractor(item))
      );
    }

    setItems(pageItems);
    setIsAllSelected(checked);
    setSelectedItems(newItems);
  };

  const apply = async (): Promise<void> => {
    const newItems = actions.onBeforeSet?.(selectedItems) ?? selectedItems;
    dispatch(actions.setItemsOnState(newItems));
    history.push(redirectUrl, locationRef.current);
  };

  const columns: any = Object.entries(tableColumns).map(([key, column]) => (
    <Table.Column key={key} {...column} />
  ));

  const addAllItems = (): Promise<void> => {
    if (onAddAllItems) {
      return setIsLoading(
        onAddAllItems().then(() =>
          history.push(redirectUrl, locationRef.current)
        )
      );
    }

    return Promise.resolve();
  };

  if (!redirectUrl) {
    return null;
  }

  return (
    <>
      <Loading loading={isLoading || loading} />
      {(onAddAllItems || additionalFilterComponent) && (
        <Row className="mb-xs">
          {onAddAllItems && (
            <Col md={3}>
              <InlineButton
                label="Adicionar os itens de todas as páginas"
                onClick={addAllItems}
              />
            </Col>
          )}
          {additionalFilterComponent}
        </Row>
      )}

      <TableContainer>
        {SearchFilterComponent ? (
          <SearchFilterComponent onSearch={doSearch} />
        ) : (
          <SearchFilter fields={searchFields} search={doSearch} />
        )}
        <Table
          values={items}
          defaultSort={defaultSort}
          sortOnHeaderColumnClick={doSortChange}
          keyExtractor={keyExtractor}
          columnSelector={columnSelector}
          highlight={highlight}
          footer={
            <tfoot>
              <tr>
                <td
                  colSpan={columns.length}
                  className="right hidden-xs container"
                >
                  <b>Selecionado(s): </b>
                  <span className="td-content">{selectedItems.length}</span>
                </td>
              </tr>
            </tfoot>
          }
        >
          <Table.Column
            headerClassName="column-checkbox no-print"
            className="column-checkbox no-print"
            header={
              onlyOneItem ? (
                <></>
              ) : (
                <div className="hidden-xs">
                  <Checkbox
                    id="check-all"
                    checked={isAllSelected}
                    onChange={selectAllItems}
                  />
                </div>
              )
            }
            value={(item: T, index: number) => {
              const key = keyExtractor(item, index);
              if (showItemCheckbox(item)) {
                return (
                  <Checkbox
                    key={key}
                    id={`checkbox-${key}`}
                    onChange={event => selectItem(event, item, index)}
                    checked={item.selected}
                  />
                );
              }
              return null;
            }}
          />
          {columns}
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </TableContainer>

      <div className="btn-save">
        <FAB
          icon="check"
          onClick={apply}
          iconColor="white"
          title="Adicionar"
          disabled={!selectedItems.length}
        />
      </div>
    </>
  );
};
