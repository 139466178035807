import { Col, DisplayData, FormattedDate, Row } from '@elotech/components';
import React from 'react';
import { useSelector } from 'react-redux';
import { getIsLoggedSelector } from 'state';

import { TipoRequerenteMp, TipoRequeridoMp } from '../../../types';

type Props = { processo: any };

const ProcedimentoInternoViewSection: React.FC<Props> = ({ processo }) => {
  const isLogged = useSelector(getIsLoggedSelector);
  return (
    <>
      <Row>
        <Col sm={6} md={6}>
          <DisplayData title="Data Autuação">
            <FormattedDate value={processo.processoMp?.dataAutuacao} />
          </DisplayData>
        </Col>
      </Row>
      <Row>
        <Col sm={4} md={4}>
          <DisplayData title="Tipo Requerido">
            {TipoRequeridoMp[processo.processoMp?.tipoRequerido]}
          </DisplayData>
        </Col>
        <Col sm={8} md={8}>
          <DisplayData title="Requerido">
            {processo.processoMp?.requerido}
          </DisplayData>
        </Col>
      </Row>
      <Row>
        <Col sm={4} md={4}>
          <DisplayData title="Tipo Requerente">
            {TipoRequerenteMp[processo.processoMp?.tipoRequerente]}
          </DisplayData>
        </Col>

        {(isLogged || processo.processoMp?.requerenteSigiloso === false) && (
          <Col sm={8} md={4}>
            <DisplayData title="Requerente">
              {processo.processoMp?.requerente}
            </DisplayData>
          </Col>
        )}
        {isLogged && (
          <Col sm={8} md={4}>
            <DisplayData title="Requerente Sigiloso">
              {processo.processoMp?.requerenteSigiloso ? 'Sim' : 'Não'}
            </DisplayData>
          </Col>
        )}
      </Row>
      <Row>
        <Col sm={5} md={4}>
          <DisplayData title="Distribuição">
            {processo.processoMp?.distribuicao?.nomeUsuario}
          </DisplayData>
        </Col>
        <Col sm={5} md={6}>
          <DisplayData title="Procedimento Origem">
            {processo.processoReferencia}
          </DisplayData>
        </Col>
      </Row>
    </>
  );
};

export default ProcedimentoInternoViewSection;
